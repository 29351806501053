const SettingsMenu = [
  {
    heading: "menu",
    route: "/settings",
    pages: [
      {
        heading: "Message of The Days",
        route: "/settings/mod",
        fontIcon: "fas fa-comment-dots",
        svgIcon: "media/icons/duotune/general/gen025.svg",
      },
      // {
      //   heading: "Target Year",
      //   route: "/settings/target-year",
      //   fontIcon: "fas fa-calendar-alt",
      //   svgIcon: "media/icons/duotune/general/gen025.svg",
      // },
      {
        heading: "Tranche",
        route: "/settings/tranche",
        fontIcon: "fas fa-chart-line",
        svgIcon: "media/icons/duotune/general/gen025.svg",
      },
      // {
      //   heading: "Geo Tree",
      //   route: "/settings/geo-tree",
      //   fontIcon: "fas fa-sitemap",
      //   svgIcon: "media/icons/duotune/general/gen025.svg",
      // },
      {
        heading: "Bank",
        route: "/settings/bank",
        fontIcon: "fas fa-money-check-alt",
        svgIcon: "media/icons/duotune/general/gen025.svg",
      },
      {
        heading: "Mobile Banking",
        route: "/settings/mobile_bank",
        fontIcon: "fas fa-money-check-alt",
        svgIcon: "media/icons/duotune/general/gen025.svg",
      },
      // {
      //   heading: "Assessment Type",
      //   route: "/settings/assessment-type",
      //   fontIcon: "fas fa-graduation-cap",
      //   svgIcon: "media/icons/duotune/general/gen025.svg",
      // },
      // {
      //   heading: "Claim Milestone",
      //   route: "/settings/claim-milestone",
      //   fontIcon: "fas fa-map-signs",
      //   svgIcon: "media/icons/duotune/general/gen025.svg",
      // },
      // {
      //   heading: "Blood Groups",
      //   route: "/settings/blood-groups",
      //   fontIcon: "fas fa-tint",
      //   svgIcon: "media/icons/duotune/general/gen025.svg",
      // },
      // {
      //   heading: "Educational Qualifications",
      //   route: "/settings/educational-qualifications",
      //   fontIcon: "fas fa-book-open",
      //   svgIcon: "/media/icons/duotune/general/gen026.svg",
      // },
      {
        heading: "Ethnic Groups",
        route: "/settings/ethnic-groups",
        fontIcon: "fas fa-user-friends",
        svgIcon: "/media/icons/duotune/general/gen026.svg",
      },
      // {
      //   heading: "Holidays",
      //   route: "/settings/holidays",
      //   fontIcon: "fas fa-calendar-check",
      //   svgIcon: "/media/icons/duotune/general/gen027.svg",
      // },
      // {
      //   heading: "Industry Sectors",
      //   route: "/settings/industry-sectors",
      //   fontIcon: "fas fa-industry",
      //   svgIcon: "/media/icons/duotune/general/gen027.svg",
      // },
      // {
      //   heading: "Industry Sub Sectors",
      //   route: "/settings/industry-sub-sectors",
      //   fontIcon: "bi-dashboard",
      //   svgIcon: "/media/icons/duotune/general/gen027.svg",
      // },
      // {
      //   heading: "Religions",
      //   route: "/settings/religions",
      //   fontIcon: "fas fa-user-circle",
      //   svgIcon: "/media/icons/duotune/general/gen027.svg",
      // },
      // {
      //   heading: "Training Milestone",
      //   route: "/settings/training-milestone",
      //   fontIcon: "fas fa-map-pin",
      //   svgIcon: "/media/icons/duotune/general/gen027.svg",
      // },
      // {
      //   heading: "Login Log",
      //   route: "/settings/login-log",
      //   fontIcon: "fas fa-sign-in-alt",
      //   svgIcon: "/media/icons/duotune/general/gen027.svg",
      // },
      // {
      //   heading: "Dashboard Widget",
      //   route: "/settings/dashboard-widget",
      //   fontIcon: "bi-dashboard",
      //   svgIcon: "/media/icons/duotune/general/gen027.svg",
      // },

      // {
      //   heading: "Assessor Organization",
      //   route: "/settings/assessor-organization",
      //   fontIcon: "fa-user-secret",
      // },
    ],
  },
];

export default SettingsMenu;
